@font-face {
  font-family: "super smash tv";
  src: url("./fonts/sstv.ttf");
}

* {
  font-family: inherit;
}
p {
  margin: 0;
  padding: 0;

  white-space: nowrap;
  overflow: hidden;
}

body {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
  text-align: center;
  height: 100vh;
  max-width: 100vw;
  background-color: #89c9ff;
}
select {
  border: 2px #c4c4c4 solid;
  padding: 0 2%;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

nav ul li a {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  margin: 0;
  padding: 0;
  background-color: rgb(239, 239, 239);
  border: 1px rgb(100, 100, 100) solid;
}
button:focus {
  outline: 0;
}

input[type="range"] {
  margin: 5% 0;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  height: 0.25rem;
  border: 1px solid #bdc3c7;
  background-color: #7c7c7c;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #ecf0f1;
  border: 1px solid #bdc3c7;
  width: 0.51rem;
  height: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  background-color: #ecf0f1;
  border: 1px solid #bdc3c7;
  width: 0.51rem;
  height: 1rem;
  border-radius: 10px;
  cursor: pointer;
}
.instrument-container {
  max-height: 80vh;
  max-width: 96vw;
  padding: 3% 0;
  width: 95%;
  min-height: 50%;
  background: #7c7c7c;
  border: 8px #c4c4c4 solid;
  border-radius: 20px;

  display: grid;
  grid-template-columns: 0.75fr 1.25fr;
}

.instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 98vw;
  min-height: 96vh;
  max-height: 96vh;
  margin: auto;

  border: 9px black solid;
  display: grid;
  grid-template-rows: 0.5fr 5fr 1fr;
  grid-template-columns: 1fr 1fr;
  border-radius: 9px;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: opacity 350ms linear;
  opacity: 0.95;
  z-index: 1;
  position: absolute;
  background: black;
}

.instructions__title {
  grid-area: 1 / 1 / 2 / 3;
  color: white;
  text-align: center;
  font-family: "super smash tv";
  font-size: 6.5vw;
  margin: 0 auto;
}
.instructions__title > span {
  display: block;
}

#title {
  margin-bottom: 0%;
}

@keyframes loadingpulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  95% {
    opacity: 1;
  }
}

#click-load {
  margin: 1% auto 2% auto;
  height: 100%;
  width: 100%;
  font-size: 2.2vw;
  text-align: center;
  animation: loadingpulse 2s infinite;
}

.instructions__drumpads-help {
  align-self: self-start;
  max-height: 100%;
}
.ints-pad__key-enabled {
  background: white;
  color: black;
}
.instructions__loading-text {
  grid-area: 3 / 1 / 4 / 3;
  color: white;
  font-family: "super smash tv";
  font-size: 5vw;
  padding-left: 7%;

  animation: loadingpulse 1s infinite;
}

.drumpads__info,
.pad_keys__text {
  color: white;
  font-size: 2.85vh;
  text-align: left;
  font-family: "super smash tv";
  margin: 0 12.5%;
}

.instructions__drumpads-help__keys {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: 2 / 1/ 3 /2;
  grid-gap: 1vw;
  background-color: #e8e8e8;
  border-radius: 8px;
  max-width: 55%;
  margin: 5% auto 0 auto;
  padding: 2%;
}

.instructions__drumpads-help__keys > li {
  font-family: "helvetica neue";
  box-sizing: border-box;
  border: 1px rgb(118, 118, 118) solid;
  background: grey;
  color: white;
  font-size: 5vh;

  width: 100%;
  padding: 20% 0;
  border-radius: 8px;
}

.instructions__sequencer-help {
  grid-area: 2 / 2 / 3 / 3;

  font-family: "super smash tv";
  font-size: 3.3vh;
  padding: 0;
  text-align: left;
  align-self: self-start;
  color: white;
  margin: 0 12.5%;
}

.drum-machine {
  max-height: 75vh;
  width: 75%;
  background-color: #e8e8e8;
  padding: 2vw 2.5vw;
  font-family: "Helvetica Neue";
  margin: auto;
  border: 8px #c4c4c4 solid;
  border-radius: 10px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.15);
}

.drum-machine__screen {
  min-height: 12%;

  font-family: "super smash tv";
  background-color: #4880ff;
  color: white;
  font-weight: normal;
  padding: 3% 4%;
  margin-bottom: 2vw;
  border-radius: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes slide {
  from {
    left: 100%;
    transform: translate(100%, 0);
  }
  to {
    left: 0;
    transform: translate(0, 0);
  }
}

.animate-text {
  animation-name: slide;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-direction: left;
  animation-iteration-count: 1;
}

.drum-machine__screen__title {
  min-height: 4.5vh;
  font-size: 2.5vw;
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: left;

  white-space: nowrap;
}

.drum-machine__screen__subtitle {
  font-size: 1.5vw;
  min-height: 3vh;
  margin-top: 1%;
  margin-bottom: 2%;
  text-align: left;

  white-space: nowrap;
}

ul.drum-machine__pads {
  list-style: none;
  width: 100%;

  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1vw;
}

ul.drum-machine__pads li button {
  position: relative;
  width: 100%;

  padding-top: 100%;

  background: #c4c4c4;
  border: 0;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: 4px 3px 5px rgba(0, 0, 0, 0.2);
}

ul.drum-machine__pads li button:active,
.button-active {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) !important;
  transform: translateY(-1px);

  -webkit-transform: translateY(-1px);
}

.drum-machine__pads__label {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1.5vw;
  color: #4a4a4a;
}

.js-upload-button input {
  display: none;
}

.js-upload-button {
  position: absolute;
  top: 0;
  right: 0;

  padding: 0.4vw 0.6vw;
  border-radius: 0.2vh 0.4rem;

  background-color: #7c7c7c;
  color: white;
  font-size: 0.8em;
  cursor: pointer;
}

/* sequencer styling */
.sequencer {
  min-height: 80%;
  max-height: 75vh;
  max-width: 60vw;
  width: 85%;
  font-family: "super smash tv";
  color: #4a4a4a;
  background-color: #e8e8e8;
  margin: 0 auto;

  display: grid;
  grid-template-rows: 0.7fr 3fr;
  padding: 1vw 1.5vw 2vw 1.5vw;
  border: 8px #c4c4c4 solid;
  border-radius: 10px;

  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.15);
}

.sequencer__controls {
  padding: 0%;
  width: 100%;
  height: 80%;
  display: inline-grid;
  grid-template-columns: 0.75fr 0.75fr 0.75fr;
  align-items: center;
  margin: auto;
  border-radius: 3px;

  overflow: hidden;
}

.sequencer__controls__buttons {
  width: 100%;
  height: 100%;
  grid-template-columns: 0.5fr 0.75fr 0.75fr;
  display: inline-grid;
  margin-right: 0%;
  font-family: "super smash tv";
}

.sequencer__controls__buttons button {
  font-size: 1.5vw;
  padding: 5% 2%;
  line-height: 0.9;
  box-sizing: border-box;
  border: 5px #c4c4c4 solid;
  margin: 0% 0%;
  color: black;
}

@keyframes resetwarning {
  25% {
    color: rgba(255, 255, 255, 1);
  }
  50% {
    color: rgba(255, 255, 255, 1);
    background: rgba(234, 84, 85, 1);
  }
  65% {
    color: rgba(255, 255, 255, 1);
  }
}

.sequencer__controls__buttons__reset:hover,
.sequencer__controls__buttons__reset-effects:hover {
  animation: resetwarning 2.5s linear infinite;
}
.sequencer__controls__buttons__reset button:active,
.sequencer__controls__buttons__reset-effects button:active {
  transform: translateY(-1px);
  background: #dc143c;
  -webkit-transform: translateY(-1px);
}

.sequencer__controls__tempo {
  box-sizing: border-box;
  height: 100%;
  display: inline-grid;
  padding: 0% 2%;
  background: #ecf0f1;
  border: 5px #7c7c7c solid;
  outline: none;
}

.tempo-info {
  font-size: 1.3vw;
  text-align: center;
}

#tempo {
  height: 10%;
  width: 100%;
}

select.beat-selector {
  box-sizing: border-box;
  border: 5px #c4c4c4 solid;
}
.beat-selector {
  font-size: 1.3vw;
  width: 96%;
  margin: 0 2%;
  height: 100%;
}
.beat-selector:active {
  border: 5px #4880ff solid;
}

.beat-selector option {
  font-family: helvetica;
  padding: 1%;
}
.sequencer__display__track-panel-div {
  display: contents;
}
.sequencer__display {
  display: grid;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
  border-radius: 2px 2px 8px 8px;
  overflow: hidden;
}

.sequencer__display__track {
  display: grid;
  height: 100%;
  grid-template-columns: 5% 15% repeat(16, 1fr);

  background: rgba(255, 255, 255, 0.2);
  flex-direction: column;
}

.sequencer__display__track__show-effects {
  background: rgba(255, 255, 255, 0.1);
  border: 0;
}

.sequencer__display__track__show-effects:hover {
  box-sizing: border-box;
  width: 86%;
  height: 86%;
  margin: 8%;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.3);
}

.sequencer__display__track__button {
  font-size: 1.5vw;
  height: 100%;
  width: 100%;

  padding: 0;
  margin: 0;

  background: rgba(255, 255, 255, 0.1);
  border: 0;
}

.sequencer__display__track__effects-panel {
  font-size: 1vw;
  height: 100%;
  width: 100%;
  text-align: center;
  background: lightblue;
  display: flex;
  flex-direction: column;
}
.sequencer__display__track__effects-panel select {
  font-size: 0.8vw;
  padding: 2% 2%;
}
.effect-panel-dropdown {
  display: inherit;
  transition: height 100ms ease-out;
}

.hide {
  display: none;
  transition: height 100ms ease-in;
}

.sequencer__display__track__effects-panel__controls {
  display: grid;
  grid-template-columns: 5% repeat(3, 1fr);
  grid-template-rows: 0.5fr 1fr;
}
.effects-panel__controls__test-button {
  padding: 0;
}
.effects-panel__controls__reset-button {
  font-size: 0.9vw;
  border-radius: 0 0 0 8px;
  grid-area: 2 / 1/ 3 / 2;
}
.effects-panel__controls__gain {
  grid-template-rows: 40% 60%;
  display: grid;
  grid-area: 1 / 2 / 2 /3;
  align-items: center;
  padding: 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__pan {
  grid-template-rows: 40% 60%;
  display: grid;
  grid-area: 1 / 3 / 2 /4;

  align-items: center;
  padding: 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__pitch {
  display: grid;
  grid-template-rows: 40% 60%;
  grid-area: 1 / 4 / 2 /5;

  align-items: center;
  padding: 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__filter {
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 50% 50%;
  display: grid;
  grid-area: 2 / 2 / 3 /3;

  text-align: left;
  align-items: center;
  padding: 0 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__filter__q-info,
.effects-panel__controls__filter__q-input,
.effects-panel__controls__filter__freq-info,
.effects-panel__controls__filter__freq-input,
.effects-panel__controls__filter > input {
  width: 90%;
}

.effects-panel__controls__delay {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 40% 60%;
  display: grid;
  grid-area: 2 / 3 / 3 / 4;

  text-align: left;
  align-items: center;
  padding: 0% 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__reverb {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 25% 75%;
  display: grid;
  grid-area: 2 / 4 / 3 / 5;

  text-align: left;
  align-items: center;
  padding: 0 2%;

  margin: 1%;
  background-color: rgba(211, 211, 211, 0.3);
}

.effects-panel__controls__reverb__wet {
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;
}

.sequencer__display__track__name {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 1.5vw;
  background-color: rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.clicked {
  text-align: center;
  color: yellow;
  opacity: 1;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1175px) {
  body {
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
  }
  .instructions {
    min-height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
  }

  .instructions__title {
    font-size: 9vw;
    margin: 1.5% 0 3% 0;
  }
  #click-load {
    font-size: 3.5vw;
  }

  .instrument-container {
    box-sizing: border-box;
    padding: 2% 2% 3% 2%;

    height: 100%;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;

    overflow: hidden;
    border: none;
    border-radius: 0;
    display: grid;
    background-color: #e8e8e8;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: 0.75fr 1.25fr 3fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .drum-machine {
    display: contents;
    flex-direction: row;
    margin: 0;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .drum-machine__screen {
    height: 95%;
    margin: 0;
    margin-bottom: 5%;
    padding: 0;
    padding-left: 4%;
    border-radius: 9px 0.2rem 0.3rem;
    align-self: flex-start;
    grid-area: 1 / 1 / 2 / 2;
  }

  .drum-machine__screen__title {
    font-size: 4vw;
    margin: 2% 0;
  }

  .drum-machine__screen__subtitle {
    margin: 0;
    font-size: 3vw;
  }

  ul.drum-machine__pads {
    grid-area: 1 / 2 / 3 / 3;
    width: 98%;
    grid-gap: 0.3rem 0.4rem;
    padding: 0;
    padding-left: 2%;
    margin: 0;
    justify-self: flex-end;
    align-items: start;
    box-sizing: border-box;
  }

  ul.drum-machine__pads li {
    height: 100%;
  }
  ul.drum-machine__pads li button {
    max-width: 100%;
    padding-top: 0;
    height: 100%;
    border-radius: 0.2rem;
    box-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }

  p.drum-machine__pads__label {
    font-size: 2.5vw;
  }

  .sequencer {
    display: contents;
    overflow: hidden;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .sequencer__controls {
    grid-area: 2 / 1 / 3 / 2;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
  }

  .sequencer__controls__buttons {
    display: contents;
  }

  .beat-selector {
    grid-area: 1 / 2 / 2 / 3;
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 10%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    font-family: inherit;

    background-color: rgb(239, 239, 239);
    border: 1px rgb(118, 118, 118) solid;
    border-radius: 0;
  }

  .sequencer__controls__buttons button {
    height: 100%;
    font-size: 2.2vw;
    font-family: inherit;
  }
  .sequencer__controls__buttons button:active {
    border: 0.1px black solid;
  }
  .sequencer__controls__buttons__play {
    grid-area: 1 / 1 / 2 / 2;
  }

  .sequencer__controls__buttons__reset {
    grid-area: 2 / 1 / 3 / 2;
  }

  .sequencer__controls__buttons__reset-effects {
    grid-area: 2 / 2 / 3 / 3;
  }

  .sequencer__controls__tempo {
    box-sizing: border-box;
    background: #c4c4c4;
    border: 3px #7c7c7c solid;
    border-radius: 2px;
    height: 90%;
    padding: 2% 0;
    align-self: flex-end;
    grid-area: 3 / 1 / 4 / 3;
  }

  #tempo {
    width: 85%;
    margin: auto;
  }
  .tempo-info {
    font-size: 2vh;
  }
  .beat-selector {
    text-align: center;
    font-size: 2.2vw;
    padding: 0 5%;
  }

  .sequencer__display {
    min-height: 93%;
    max-height: 97%;
    background: black;
    overflow-y: none;
    height: 94%;
    margin-top: 3%;
    display: grid;
    border-radius: 7px 7px 9px 9px;
    grid-area: 3 / 1 / 4 / 3;
  }

  .sequencer__display__track-panel-div {
    display: contents;
  }
  .sequencer__display__track {
    height: 100%;
  }
  .sequencer__display__track__name {
    font-size: 2.5vh;
  }

  .sequencer__display__track__button {
    border: 1px rgba(0, 0, 0, 0.02) solid;
    font-size: 3vh;
    padding: 0;
  }

  .hide {
    display: none;
  }

  .effect-panel-dropdown {
    display: flex;
  }

  .sequencer__display__track__effects-panel {
    font-size: 1.7vw;
    max-height: 100%;
    width: 100%;
  }
  .sequencer__display__track__effects-panel select {
    font-size: 1.3vw;
  }
  .sequencer__display__track__effects-panel__controls {
    grid-template-rows: 1fr 1.5fr;
    max-height: 100%;
    height: 100%;
  }

  .sequencer__display__track__effects-panel__controls span,
  .sequencer__display__track__effects-panel__controls select {
    font-size: 2.25vw;
  }

  .effects-panel__controls__reset-button {
    font-size: 1.5vw;
  }

  .effects-panel__controls__gain,
  .effects-panel__controls__pan,
  .effects-panel__controls__pitch {
    align-items: center;
    text-align: left;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.6fr;
  }
}
@media only screen and (max-width: 710px) {
  input[type="range"]::-webkit-slider-thumb {
    height: 1rem;
  }
  .instructions__title {
    font-size: 8vh;
    margin: 8% 0;
  }
  .drumpads__info {
    font-size: 3.85vh;
  }
  .pad_keys {
    display: none;
  }
  #click-load {
    font-size: 5vw;
  }

  .pad_keys {
    display: none;
  }

  .instructions__sequencer-help__text {
    font-size: 3.15vh;
  }
  .instructions__loading-text {
    font-size: 8vh;
  }
  .instrument-container {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: relative;
  }
  .drum-machine__screen {
    padding: 5%;
    height: 75%;
    border-radius: 0 2px 2px 2px;
  }
  .drum-machine__screen__title {
    margin-top: 6%;
    min-height: 3.5vh;
  }
  ul.drum-machine__pads {
    margin: 0 auto;
    padding: 0;
  }
  ul.drum-machine__pads li button {
    padding-top: 12.5vh;
  }

  p.drum-machine__pads__label {
    font-size: 3.5vw;
  }
  .sequencer__controls {
    margin: 0;
    padding: 0;
  }

  .sequencer__controls__buttons button {
    font-size: 2vh;
    border: 3px #c4c4c4 solid;
  }
  .sequencer__controls__tempo {
    height: 90%;
  }
  .beat-selector {
    font-size: 3vw;
    padding: 2% 5%;
  }

  select.beat-selector {
    border: 3px #c4c4c4 solid;
  }

  .tempo-info {
    align-items: center;
  }

  .sequencer__display {
    height: 100%;
    min-height: 0;
    overflow: auto;
  }
  .sequencer__display__track-panel-div {
    display: contents;
  }
  .displaying-track-effect-div {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
  }
  .sequencer__display__track {
    height: 100%;
    overflow: auto;
    grid-template-columns: 6% 18% repeat(16, 1fr);
  }
  .sequencer__display__track__button.clicked {
    border: 1px yellow solid;
  }
  .sequencer__display__track__name {
    font-size: 1.8vh;
  }
  .sequencer__display__track__button {
    border: 1px rgba(0, 0, 0, 0.25) solid;
    font-size: 2.5vh;
  }
  .sequencer__display__track__show-effects {
    width: 100%;
    height: 100%;
  }
  .sequencer__display__track__show-effects:hover {
    border-radius: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .effect-panel-dropdown {
    height: 82%;
  }
  .displaying-effect-panel,
  .effect-panel-dropdown {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
    margin: 8%;
  }
  .displaying-effect-panel {
    grid-template-columns: repeat(18, auto);
    height: 6%;
    position: absolute;
    top: 0%;
    right: 0%;

    border-radius: 1vh 1vh 0 0;
    overflow: hidden;
  }
  .displaying-effect-panel > .sequencer__display__track__show-effects {
    font-size: 4vh;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.25) 100%
    );
  }
  .displaying-effect-panel > .sequencer__display__track__name {
    padding: 0 2%;
    font-size: 3vh;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .sequencer__display__track__effects-panel {
    position: absolute;
    top: 6%;
    right: 0%;
    border-radius: 0 0 1vh 1vh;
    overflow: hidden;
  }
  .sequencer__display__track__effects-panel__controls {
    max-height: 100%;
    overflow-y: auto;
    padding: 1.5%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 6% repeat(3, 0.5fr) repeat(3, 1fr);
  }

  .sequencer__display__track__effects-panel select {
    font-size: 3.5vw;
  }

  .sequencer__display__track__effects-panel__controls span {
    font-size: 4vw;
  }

  .effects-panel__controls__gain,
  .effects-panel__controls__pan,
  .effects-panel__controls__pitch,
  .effects-panel__controls__filter,
  .effects-panel__controls__delay,
  .effects-panel__controls__reverb {
    margin: 0.5%;
    padding: 1% 3%;
    text-align: left;
    font-size: 2.3vh;
    grid-template-columns: 0.4fr 0.6fr;
  }

  .effects-panel__controls__test-button {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 2.5vh;
    margin: 0% 0% 2% 0.5%;
  }

  .effects-panel__controls__reset-button {
    grid-area: 1 / 2 / 2 / 3;
    font-size: 2.5vh;
    margin: 0% 0.5% 2% 0%;
    border-radius: 0;
  }

  .effects-panel__controls__gain {
    grid-area: 2 / 1 / 3 / 3;
    grid-template-rows: 1fr;
  }

  .effects-panel__controls__pan {
    grid-area: 3 / 1 / 4 / 3;
    grid-template-rows: 1fr;
  }

  .effects-panel__controls__pitch {
    grid-area: 4 / 1 / 5 / 3;
    grid-template-rows: 1fr;
  }

  .effects-panel__controls__filter {
    grid-area: 5 / 1 / 6 / 3;
    grid-template-rows: 1fr 1fr;
  }

  .effects-panel__controls__delay {
    grid-area: 6 / 1 / 7 / 3;
    grid-template-rows: 1fr 1fr;
  }

  .effects-panel__controls__reverb {
    grid-area: 7 / 1 / 8 / 3;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 0.25fr 0.75fr;
  }
}
@media all and (max-height: 735px) {
  .instrument-container {
    background: #e8e8e8;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    max-height: 100vh;
    max-width: 100vw;
    border-radius: 0;
    border: none;
  }
  .drum-machine {
    max-height: 100%;
  }
  .sequencer {
    max-height: 100%;
  }
  .sequencer__controls {
    overflow: hidden;
  }
}
